// import "../styles/index.scss"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import Footer from "../components/footer"
import GenericContent from "../components/generic-content.js"
import GridItemWithHeading from "../components/grid-item-with-heading"
import Header from "../components/header"
import SEO from "../components/seo"

export default class ServicesTemplate extends React.Component {
  render() {
    const {
      image1,
      image2,
      image3,
      content1,
      content2,
      title,
      description,
      metaImage,
    } = {
      ...this.props.data.allMarkdownRemark.nodes[0].frontmatter,
    }
    return (
      <div>
        <SEO
          title={title}
          description={description}
          metaImage={metaImage}
          pathname={this.props.location.pathname}
        />
        <Header></Header>
        <main className="grid grid-col-1-2-3">
          <div className="grid-item-1x2">
            <GridItemWithHeading
              image={image1.childImageSharp.fluid}
              heading={title}
            ></GridItemWithHeading>
          </div>
          <div className="grid-item-1x1">
            <Img fluid={image2.childImageSharp.fluid} />
          </div>
          <div className="grid-item-1x1">
            <GenericContent
              flex="true"
              padding="padding-tb-s padding-lr-xl"
              content={content1}
            ></GenericContent>
          </div>
          <div className="grid-item-1x1">
            <GenericContent
              flex="true"
              padding="padding-tb-s padding-lr-s"
              content={content2}
            ></GenericContent>
          </div>
          <div className="grid-item-1x1">
            <Img fluid={image3.childImageSharp.fluid} />
          </div>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}

// TODO Seperate graphql file? Sharing images on other templates?
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "services" } } }
    ) {
      nodes {
        frontmatter {
          image1 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image3 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          content1
          content2
          description
          metaImage {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`
